import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.jpeg"; // Ensure you have appropriate images or use placeholders
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.jpeg";
import projImg4 from "../assets/img/project-img4.jpeg";
import projImg5 from "../assets/img/project-img5.jpeg";
import projImg6 from "../assets/img/project-img6.jpeg";
import projImg7 from "../assets/img/project-img7.jpeg";
import projImg8 from "../assets/img/project-img8.jpeg";
import projImg9 from "../assets/img/project-img9.png";
import projImg10 from "../assets/img/project-img10.jpeg";
import projImg11 from "../assets/img/project-img11.png";
import projImg12 from "../assets/img/project-img12.jpg";
import projImg13 from "../assets/img/project-img13.jpeg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "IT Consulting at FDM Group",
      description: "Engaged in IT compliance and support, managing change and access management with a focus on industry standards like PCI DSS, COBIT, and NIST.",
      imgUrl: projImg1,
    },
    {
      title: "Business Analysis at TD",
      description: "Led business requirement gathering and project documentation for system enhancements, using Agile and Waterfall methodologies to ensure alignment with business objectives.",
      imgUrl: projImg2,
    },
  ];

  const projects1 = [
    {
      title: "Security Framework Overhaul at FDM Group",
      description: "Redesigned the security framework, incorporating PCI DSS and NIST standards to enhance data protection and compliance. Implemented robust access control and threat detection systems, significantly reducing vulnerability to cyber threats.",
      imgUrl: projImg3,  // Consider using an image that symbolizes robust security measures or IT security environments.
    },
    {
      title: "Supply Chain Optimization at TD",
      description: "Analyzed and restructured the supply chain system using business analysis principles to streamline operations. Employed both Agile and Waterfall methodologies to integrate new software solutions that improved efficiency and reduced costs.",
      imgUrl: projImg4,  // Use an image depicting supply chain management or business optimization.
    },
    {
      title: "Advanced Data Analytics Implementation",
      description: "Developed and deployed advanced analytics solutions, utilizing machine learning models to predict market trends and consumer behavior. Created dynamic dashboards and visualizations in Tableau to aid strategic decision-making across departments.",
      imgUrl: projImg5,  // Choose an image that reflects data analysis, such as graphs, charts, or data workflows.
    },
    {
      title: "Cloud Migration Strategy for FDM Group",
      description: "Led the migration of IT infrastructure to cloud environments (AWS and Azure), enhancing system scalability and performance. Ensured seamless transition and minimal disruption through meticulous planning and execution.",
      imgUrl: projImg6,  // Consider an image depicting cloud computing or cloud infrastructure.
    },
    {
      title: "Regulatory Compliance Project at TD",
      description: "Directed a comprehensive compliance project ensuring alignment with COBIT and COSO frameworks. Facilitated cross-departmental collaboration to address and mitigate compliance risks effectively.",
      imgUrl: projImg7,  // Use an image related to compliance, regulation, or corporate governance.
    },
    {
      title: "IT Support System Enhancement",
      description: "Upgraded the IT support framework to improve incident response times and user satisfaction. Implemented cutting-edge ITSM tools and trained the support team on best practices and operational efficiency.",
      imgUrl: projImg8,  // Choose an image that represents IT support or service management.
    }
  ];

  const projects2 = [
    {
      title: "Google Data Analytics Professional Certificate",
      description: "Certification focusing on data analysis and visualization techniques, including the use of tools such as SQL, R, and Python for data-driven decision-making.",
      imgUrl: projImg9,  // Ensure this image relates to the theme of data analytics
    },
    {
      title: "AWS Certified Cloud Practitioner",
      description: "Credential confirming my understanding of AWS Cloud's fundamental infrastructure, architectural principles, key services, and their use cases, security, and compliance.",
      imgUrl: projImg10,  // Use an image that is relevant to AWS or cloud computing
    },
    {
      title: "CompTIA Security+ (In progress)",
      description: "Certification that validates the core skills required for cybersecurity roles, enhancing understanding of security concepts, best practices, and threat management.",
      imgUrl: projImg11,  // Use an image that symbolizes cybersecurity or IT security
    }
  ];

  const education = [
    {
      title: "Master’s in Information System Security Management",
      description: "Specialized in Information System Security, with in-depth studies on networking, cloud technologies, and data analysis. Published a research paper on malware traffic prediction in IoT networks.",
      imgUrl: projImg12,  // Ensure this image relates to the theme of data analytics
    },
    {
      title: "Bachelor of Engineering - Computer Science",
      description: "Focused on Data Structures, Cybersecurity, and Cloud Technologies. Gained foundational knowledge in computer architectures and software development.",
      imgUrl: projImg13,  // Use an image that is relevant to AWS or cloud computing
    },
 
  ];


  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Education and Experience</h2>
                <p>Here are some highlights from my professional engagements, demonstrating my skills in IT consulting, business analysis, and data visualization across various roles and projects.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="zero">
                  <Nav variant="pills" className="nav-pills mb-7 justify-content-center align-items-center" id="pills-tab">
                  <Nav.Item>
                      <Nav.Link eventKey="zero">Education</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="first">Work Experience</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Certifications</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          projects1.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          projects2.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="zero">
                    <Row>
                        {
                          education.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
